import { find } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getClientOptions, getEventTypeOptions } from '../selectors/dropdowns';

import { createSpinnerPopup, closePopup } from '../actions/popup';
import { createProjectAndOrder } from '../actions/project';

import Form from './Form';

import { BASE_ZINDEX } from '../popup-factory';

class CreateProjectAndOrderPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      client_id: null,
      order_type: 'PRESENTATION'
    };

    this.onFormRef = this.onFormRef.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onCreate = this.onCreate.bind(this);
  }

  onFormRef(form) {
    this._form = form;
  }

  handleFieldChange(value, field) {
    this.setState({ [field]: value });
  }

  onCreate(values, errors) {
    if (find(errors, error => error === null)) {
      return;
    }
    this.props.onCreate(values);
  }

  render() {
    const { clients, identity, event_types, order, onClosePopup, index } = this.props;
    const { order_type } = this.state;
    const hasCapability = capability => identity.capabilities.includes(capability);
    const order_types = [
      { key: 'PRESENTATION', value: 'Presentation', show: hasCapability('CREATE-PRESENTATION') },
      { key: 'ESTIMATE', value: 'Estimate', show: hasCapability('CREATE-ESTIMATE') },
      { key: 'SALES ORDER', value: 'Sales Order', show: hasCapability('CREATE-SALES-ORDER') }
    ].filter(t => t.show);

    const order_type_name = order_types.filter(t => t.key === this.state.order_type)[0].value;

    return (
      <div id="convert-modal" className="reveal small" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', overflow: 'visible', transition: 'width 0.1s, height 0.1s' }} data-reveal aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className ="row">
          <div className ="small-12 columns">
            <h3 id="modalTitle" style={{ fontSize: '1.8rem' }}>Create a New {order_type_name}</h3>
            <a className="alert button" style={{ position: 'fixed', right: '6rem', top: '1rem' }} onClick={e => onClosePopup()}>Cancel</a>
            <a className="button" style={{position: 'fixed', right: '1rem', top: '1rem' }} onClick={(e) => {e.preventDefault(); this._form && this._form.submit();}}>Create</a>
          </div>
        </div>
        <Form
          className="row popup-content form"
          ref={this.onFormRef}
          object={{ order_type }}
          onFieldChange={this.handleFieldChange}
          onSubmit={this.onCreate}
          style={{ overflow: 'visible' }}
        >
          <Form.TextInput label="Project Name" field="job_name" value={this.state.job_name} required={true} placeholder="New Project Name" />
          <Form.Select label="Client" field="client_id" value={this.state.client_id} options={clients} searchable={true} required={true} withMarginBottom />
          <Form.Select label="Order Type" field="order_type" value={order_type} options={order_types} required={true} withMarginBottom />
          <Form.Select label="Event Type" field="event_type_id" value={this.state.event_type_id} options={event_types} searchable={true} required={true} withMarginBottom />

        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  clients: getClientOptions(state),
  event_types: getEventTypeOptions(state),
  identity: state.identity
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCreate: ({ job_name, client_id, order_type, event_type_id }) => {
    dispatch(createSpinnerPopup('Creating Project...'));
    return dispatch(createProjectAndOrder({ job_name, client_id, order_type, event_type_id })).then(
      () => dispatch(closePopup(2)),
      () => dispatch(closePopup())
    );
  },
  onClosePopup: () => dispatch(closePopup())
});

const ConnectedCreateProjectAndOrderPopup = connect(mapStateToProps, mapDispatchToProps)(CreateProjectAndOrderPopup);
export default ConnectedCreateProjectAndOrderPopup;
